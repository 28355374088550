import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import './App.css';
import { IStyle, keyframes, mergeStyles } from '@fluentui/merge-styles';
import logoText from './images/logo-text.svg'
import logoWide from './images/logo-wide.svg'
import logoWideAlt from './images/logo-wide-alt.svg'
import logoBS from './images/logo-bs.png'
import logoPS from './images/logo-ps.png'
import logoKG from './images/logo-kg2.png'
import bgBS from './images/bg-bs.jpg'

import cloudBS from './images/cloud-bs.png'
import cloudPS from './images/cloud-ps.png'
import cloudKG from './images/cloud-kg.png'
// import bgPS from './images/bg-ps.png'
// import bgKG from './images/bg-kg.png'
import logo from './images/logo.svg'
import elipsis from './images/elipsis.svg'
import xbutton from './images/xbutton.svg'

import expert from './images/expert.jpg'
import jets from './images/jets.jpg'
import intelsim from './images/intelsim.jpg'
import polsim from './images/polsim.jpg'
import ship from './images/ship.jpg'
import bootcamps from './images/bootcamps.jpg'
import gameengine from './images/gameengine2.jpg'

import ge1 from './images/ge1.jpg'
import ge2 from './images/ge2.jpg'
import ge3 from './images/ge3.jpg'
import ge4 from './images/ge4.jpg'

import p1 from './images/p1.jpg'
import p2 from './images/p2.jpg'
import p3 from './images/p3.jpg'

import teams from './images/teams.jpg'
import teams2 from './images/teams2.jpg'
import report from './images/report.jpg'
import magnify from './images/magnify.jpg'
import chess from './images/chess.jpg'
import agenda from './images/agenda.jpg'
import hourglass from './images/hourglass.jpg'
import clockwork from './images/clockwork.jpg'

import way2 from './images/way2.jpg'
import bannerBootcamp from './images/banner-bootcamp1.jpg'
import bannerIntel from './images/banner-intel1.jpg'
import bannerPolicy from './images/banner-policy1.jpg'
import bannerWargame from './images/banner-wargame1.jpg'



const lessThanLarge = "@media only screen and (max-width: 991px)"
const lessThanMedium = "@media only screen and (max-width: 599px)"

const floatLeft = mergeStyles({ float: "left" })
const floatRight = mergeStyles({ float: "right" })
const maxWidth = "90rem";
const textCenter = mergeStyles({
  textAlign: "center"
})
const textRight = mergeStyles({
  textAlign: "right"
})

const textBold = mergeStyles({
  fontWeight: "bold"
})
const textItalics = mergeStyles({
  fontStyle: "italic"
})
const imgSingle = mergeStyles({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat"
})
const imgContain = mergeStyles(imgSingle, {
  backgroundSize: "contain",

})
const imgCover = mergeStyles(imgSingle, {
  backgroundSize: "cover",

})
const shadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);";
const shadowSmall = "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);";

const obvia = mergeStyles({ fontFamily: "obvia, sans-serif" });
const smallFont = mergeStyles({
  fontSize: "0.75rem"
})
const largeFont = mergeStyles({
  fontSize: "1.5rem"
})
const mediumFont = mergeStyles({
  lineHeight: "1.5",
  fontSize: "1.25rem"
})
const xLargeFont = mergeStyles({ fontSize: "2rem" })

function bgImage(url: string) {
  return mergeStyles({ backgroundImage: `url(${url})` })
}

const red = "#991B1E";
const beige = "#fbfbf2";
const darkRed = "#6b1215";
const lightRed = "#a33134";
const textRed = mergeStyles({ color: red })
const textWhite = mergeStyles({ color: "white" })
const bgRed = mergeStyles({ backgroundColor: red })

const gray = "#343538";
const bgGray = mergeStyles({ backgroundColor: gray })
const bgGreen = mergeStyles({ backgroundColor: "#092d08" })
const redThickBorder = `0.25rem solid ${red}`
const whiteThickBorder = `0.25rem solid white`
const blackThickBorder = `0.25rem solid #343538`
const lightBlue = "#f6fbfc"

const linker = mergeStyles({
  color: "black",
  textDecoration: "none",
  "&.current-link": {
    color: "white",
    border: "1px solid #ccc",
    backgroundColor: red,
    padding: "0.5rem",
    borderRadius: "0.25rem"
  },
  ":hover:not(.current-link)": {
    color: red,

  }
})

const linkerAlt = mergeStyles({
  color: "white",
  textDecoration: "none",
  "&.current-link": {
    color: "white",
    border: "1px solid #ccc",
    backgroundColor: red,
    padding: "0.5rem",
    borderRadius: "0.25rem"
  },
  ":hover:not(.current-link)": {
    color: lightRed,
  }
})


const blackBox = mergeStyles({
  border: "1px solid black", color: "white", backgroundColor: "rgba(0,0,0,0.7)"
})
const bgBox = mergeStyles({
  border: "1px solid black", color: "white", backgroundColor: "rgba(0,0,0,0.6)"
})
const grayBox = mergeStyles({
  border: "1px solid black", color: "white", backgroundColor: "rgba(0,0,0,0.35)"
})


function WebLink(props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  const hist = useHistory();

  const ogRef = props.href;
  return <a onClick={(ev) => {
    if (ogRef) {
      hist.push(ogRef);
      ev.preventDefault();
      return true;
    }

  }} {...props}></a>
}
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function Header(props: { alt?: boolean }) {
  const loc = useLocation();
  const [showMenu, setShowMenu] = useState(false);


  let location = useLocation()

  useEffect(
    () => {
      setShowMenu(false);
    },
    [location.pathname]
  )


  return <div className={
    mergeStyles(props.alt ? mergeStyles(bgGray, { color: "white" }) : { backgroundColor: "white" }, obvia,
      {
        height: showMenu ? undefined : "4.25rem",
        width: "100%",
        maxWidth: maxWidth,
        position: "fixed",
        zIndex: 10,
        top: 0,
        display: "flex", overflow: "hidden", alignItems: "center", justifyContent: "flex-end",
        [`>.${props.alt ? linkerAlt : linker}`]: { margin: "0 1rem" }, borderBottom: props.alt ? whiteThickBorder : redThickBorder,
        [lessThanMedium]: {
          height: showMenu ? undefined : "5.5rem",
          display: "block",
          [`>.${props.alt ? linkerAlt : linker}`]: { margin: "1rem" },
          ">a": {
            display: "block",
            margin: "1rem !important"
          }

        }
      })}>
    <WebLink
      href="/"
      className={mergeStyles(imgContain, { display: "block", marginRight: "auto", marginLeft: "1rem", backgroundImage: `url(${props.alt ? logoWideAlt : logoWide})`, height: "4rem", width: "15rem" })}
    />
    <div
      onClick={() => {
        setShowMenu(!showMenu);
      }}
      className={mergeStyles({
        display: "none",
        [lessThanMedium]: {
          display: "block",
          position: "absolute",
          top: 0,
          right: "1rem",
          fontSize: "4.5rem",

        }

      })}>
      &#x2261;
    </div>
    {["why-waymark", "intelligence-simulations",
      "policy-simulations",
      "wargames",
      "bootcamps",
      "gaming-engine"].map((info) => {
        return <WebLink href={`/${info}`} className={mergeStyles(props.alt ? linkerAlt : linker, info === loc.pathname.substr(1) ? "current-link" : undefined)} >{info.split('-').map(t => t[0].toUpperCase() + t.substr(1)).join(" ")}</WebLink>
      })
    }


  </div>
}

function ButtonLink(props: { className?: string, text: string, href: string }) {
  return <a className={mergeStyles(props.className, { display: "inline-block", backgroundColor: red, borderRadius: "0.75rem", border: "0.25rem solid " + darkRed, color: "white", textDecoration: "none", padding: "0.5rem", ":hover": { backgroundColor: darkRed }, ":active": { backgroundColor: lightRed, borderColor: red } })} href={props.href}>{props.text}</a>
}
const bannerSharedInfo = {
  animationDuration: "2.5s",
  animationFillMode: "both"
};
const bannerInfo = [
  {
    image: way2, title: "Ready On Day One.", text: "Waymark equips national security analysts and decision makers with the practical skills needed to lead on day one.",
    class: mergeStyles({
      animationName: keyframes({
        from: {
          backgroundSize: "110% auto",
          backgroundPosition: "left 0"
        },
        to: {
          //backgroundSize:"100"
          backgroundSize: "100% auto",
          backgroundPosition: "left -5rem"
        }
      }),
      ...bannerSharedInfo
    }),
    textClass: { top: "2rem", left: "2rem" }
  },
  {
    image: bannerIntel, title: "Intelligence Simulations.", text: "Waymark intelligence simulations take you inside the highly secretive world of intelligence analysis.",
    class: mergeStyles({
      animationName: keyframes({
        from: {
          backgroundSize: "120% auto",
          backgroundPosition: "right -10rem"
        },
        to: {
          //backgroundSize:"100"
          backgroundSize: "110% auto",
          backgroundPosition: "right -5rem"
        }
      }),
      ...bannerSharedInfo
    }),
    textClass: { bottom: "6rem", right: "2rem" }
  },
  {
    image: bannerPolicy, title: "Policy Simulations.", text: "Waymark policy simulations give participants a seat at the table where big decisions are made and grand strategies are born.", class: mergeStyles({
      animationName: keyframes({
        from: {
          backgroundSize: "110% auto",
          backgroundPosition: "right 0"
        },
        to: {
          //backgroundSize:"100"
          backgroundSize: "100% auto",
          backgroundPosition: "right -5rem"
        }
      }),
      ...bannerSharedInfo
    }),
    textClass: { bottom: "2rem", left: "2rem" }
  },
  {
    image: bannerWargame,
    title: "Wargames.",
    text: "Waymark wargames—based on scenarios torn from tomorrow’s headlines—plunge participants into the chaotic world of crisis policy.",
    class: mergeStyles({
      animationName: keyframes({
        from: {
          backgroundSize: "120% auto",
          backgroundPosition: "center 0rem"
        },
        to: {
          //backgroundSize:"100"
          backgroundSize: "120% auto",
          backgroundPosition: "center -5rem"
        }
      }),
      ...bannerSharedInfo
    }),
    textClass: { bottom: "2rem", left: "0", right: 0, margin: "auto" }
  },
  {
    image: bannerBootcamp, title: "Bootcamps.", text: "Waymark bootcamps deliver cutting edge analytic skills and techniques, customized to your organization’s needs.",
    class: mergeStyles({
      animationName: keyframes({
        from: {
          backgroundSize: "110% auto",
          backgroundPosition: "right 0"
        },
        to: {
          //backgroundSize:"100"
          backgroundSize: "100% auto",
          backgroundPosition: "right -5rem"
        }
      }),
      ...bannerSharedInfo
    }),
    textClass: { bottom: "6rem", right: "2rem" }
  }
]

function SubPageBanner(props: { title: string, imageUrl: string, imageContent?: ReactNode | ReactNode[], imageClass?: string, children: ReactNode | ReactNode[] }) {
  return <div className={mergeStyles({ position: "relative" })}>
    <div className={mergeStyles({
      overflow: "hidden",
      borderBottom: "1px solid #ccc",
      backgroundColor: beige,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "50%",
      [lessThanMedium]: {
        width: "100%"
      }
    })}>
      <div className={mergeStyles({ padding: "1rem" }, textBold, obvia, largeFont, bgRed, textWhite)}>{props.title}</div>
      <div className={mergeStyles(imgCover, bgImage(props.imageUrl), {
        position: "absolute",
        right: 0,
        top: 0,
        width: "50%",
        bottom: 0,
        display: "flex", justifyContent: "center", alignContent: "center",
        [lessThanMedium]: {
          position: "static",
          paddingTop: "75%",
          width: "100%"
        }
      }, props.imageClass)} >{props.imageContent}</div>
      {props.children}
    </div>
  </div>

}

function SubPageSection(props: { title: string, imageUrl: string, imageRight?: boolean, children: ReactNode | ReactNode[] }) {
  return <div className={mergeStyles(pad2, {
    overflow: "hidden",
    [lessThanMedium]: {
      display: "flex",
      flexDirection: "column",
      padding: '2rem 1rem',

    }
  })}>
    <div className={mergeStyles(props.imageRight ? floatRight : floatLeft, imgCover, bgImage(props.imageUrl),
      {
        marginRight: props.imageRight ? undefined : "2rem",
        marginLeft: props.imageRight ? "2rem" : undefined,
        border: "1rem solid white",
        width: "24rem",
        height: "18rem",
        boxShadow: shadow,
        order: "2",
        [lessThanMedium]: {
          border: 0,
          boxShadow: "unset",
          width: "100%",
          margin: 0
        }
      })} />
    <div className={mergeStyles({ overflow: "hidden", order: "1" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>{props.title}</div>
    <p className={mergeStyles({
      padding: "1rem 0.5rem", overflow: "hidden", order: "3", [lessThanMedium]: {
        padding: "1rem 1rem",
        ">ul": { paddingLeft: "1rem" }
      }
    })}>
      {props.children}
    </p>
  </div>
}

function Banner() {

  const [index, setIndex] = useState(0)
  const [curTimeout, setCurTimeout] = useState<any>();
  const prm = new URLSearchParams(useLocation().search);
  const productCode = prm.get("product") as ProductsKey | undefined;

  useEffect(() => {
    if (!productCode) {
      setCurTimeout(setTimeout(() => {
        setIndex((index + 1) % (bannerInfo.length))
      }, 6000))
    }
  }, [index, setIndex, setCurTimeout, productCode])

  return <div className={mergeStyles({
    width: "100%",
    position: "relative",
    paddingTop: "55%",
    [lessThanMedium]: {
      paddingTop: "65%"
    }
  })} >
    {
      bannerInfo.map((b) => {
        return <img src={b.image} height={1} width={1} className={mergeStyles({ zIndex: -1, position: "absolute" })} />
      })
    }
    <div className={mergeStyles(bannerInfo[index].class, bgImage(bannerInfo[index].image), {
      backgroundRepeat: "no-repeat", backgroundSize: "100% auto",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%", /*borderBottom: redThickBorder*/
    })}>
      <ButtonLink href="https://portal.waymarkintel.com" className={mergeStyles(obvia, { float: "right", marginRight: "1rem", marginTop: "1rem" })} text="Portal Login" />
      <div
        className={
          mergeStyles(bannerInfo[index].textClass, blackBox, {
            position: "absolute", boxShadow: shadow, width: "60%", padding: "2rem",
            [lessThanLarge]: {
              boxSizing: "border-box",
              bottom: 0,
              left: 0,
              width: "100%",
              top: "auto",
              paddingBottom: "2rem",
            },
            [lessThanMedium]: {
              padding: "0.75rem",
              paddingBottom: "3rem"

            }
          })}



      >
        <div className={mergeStyles(obvia, xLargeFont, {
          color: "white", fontWeight: "bold",
          [lessThanMedium]: {
            fontSize: "1rem"
          }
        })}>{bannerInfo[index].title}</div>
        <span className={mergeStyles(largeFont, {
          [lessThanMedium]: {
            fontSize: "0.9rem"
          }
        })}>{bannerInfo[index].text}</span>
      </div>
      <div
        className={mergeStyles({
          textAlign: "center", backgroundColor: red, display: "flex", justifyContent: "space-evenly", padding: "0.5rem", position: "absolute", width: "10rem", bottom: "2rem", margin: "auto", right: 0,
          [lessThanLarge]: {
            bottom: "0.5rem"
          }

        })}>
        {
          bannerInfo.map((b, i) => {
            return <div onClick={() => {
              clearInterval(curTimeout);
              setIndex(i);
            }} className={mergeStyles(i === index ? "active" : undefined, slideDot)} />
          })
        }
      </div>
    </div>
  </div>
}

const footHeadLink = mergeStyles(obvia, textBold, mediumFont)


const wrapWhenSmall = mergeStyles({
  [lessThanMedium]: {
    flexWrap: "wrap",
    display: "flex",
    ">*": {
      width: "50%",
      boxSize: "border-box",
      marginBottom: "1rem"
    }
  }
})

function Footer() {
  const loc = useLocation();

  return <div className={mergeStyles(colWhenSmall, spaceEvenly, bgGray)}>
    <div className={mergeStyles({ position: "relative" })}>
      <div className={mergeStyles(textCenter, obvia, largeFont, textWhite, { marginTop: "2rem" })}>Learn More About Our Products!</div>
      <div className={mergeStyles({ display: "flex", justifyContent: "space-evenly", padding: "0rem 2rem" })}>
        {(["bs", "ps", "kg"] as ProductsKey[]).map((k) => {
          return <WebLink href={`${loc.pathname}?product=${k}`} className={mergeStyles(imgContain, bgImage(products[k].logo), productBase, {})} />;
        })}
      </div>
    </div>
    <div className={mergeStyles(colWhenSmall, spaceEvenly, textWhite, obvia, {
      alignItems: "flex-start", paddingTop: "2rem", "a": {
        color: "white",
        textDecoration: "none",
        paddingBottom: "0.25rem",
        display: "block"
      }, [lessThanMedium]: {
        display: "block",
        paddingTop: 0,
        ">*": { margin: "2rem" }
      }
    })}>
      <div>
        <div className={footHeadLink}>Why Waymark</div>
        <div className={wrapWhenSmall}>
          <WebLink href="/why-waymark" >About Us</WebLink>
          <WebLink href="/why-waymark" >The Team</WebLink>
        </div>
      </div>
      <div>
        <div className={footHeadLink}>Solutions</div>
        <div className={wrapWhenSmall}>
          <WebLink href="/intelligence-simulations" >Intelligence Simulations</WebLink>
          <WebLink href="/policy-simulations" >Policy Simulations</WebLink>
          <WebLink href="/wargames" >Wargames</WebLink>
          <WebLink href="/bootcamps" >Bootcamps</WebLink>
          <WebLink href="/gaming-engine" >Gaming Engine</WebLink>
        </div>
      </div>
      <div>

        <div className={footHeadLink}>Contact</div>
        <div className={wrapWhenSmall}>

          <WebLink href="/contact">Request a quote</WebLink>
        </div>
      </div>
    </div>
  </div>
}





const slideDot = mergeStyles({
  height: "0.75rem",
  width: "0.75rem",
  borderRadius: "calc(0.75rem / 2)",
  margin: "0 0.5rem",
  cursor: "pointer",
  backgroundColor: "black",
  "&.active": {
    backgroundColor: "white"
  }
})

const animateProductClass = keyframes({
  from: {
    backgroundSize: "90% auto",
    filter: "grayscale(100%)"
  },
  to: {
    backgroundSize: "100% auto",
    filter: "grayscale(0)"
  }
});



const productBase = mergeStyles({
  margin: "0 1rem",
  paddingTop: "30%",
  flexGrow: "1",
  backgroundSize: "90% auto",
});
const productClass = mergeStyles(productBase, {
  filter: "grayscale(100%)",
  cursor: "pointer",
  ":hover": {
    animationName: animateProductClass,
    animationDuration: "0.5s",
    animationFillMode: "both"

  } as IStyle
})

const arrowLeft = mergeStyles({
  width: "0",
  height: "0",
  borderTop: "0.4rem solid transparent",
  borderBottom: "0.4rem solid transparent",
  borderRight: "0.5rem solid white",
  display: "inline-block"
})

const arrowRightRed = mergeStyles({
  width: "0",
  height: "0",
  borderTop: "0.4rem solid transparent",
  borderBottom: "0.4rem solid transparent",
  borderLeft: "0.5rem solid " + red,
  display: "inline-block",
  [lessThanMedium]: {
    borderRight: "0.4rem solid transparent",
    borderLeft: "0.4rem solid transparent",
    borderTop: "0.5rem solid " + red,
  }
})

const arrowRightWhite = mergeStyles({
  width: "0",
  height: "0",
  borderTop: "0.4rem solid transparent",
  borderBottom: "0.4rem solid transparent",
  borderLeft: "0.5rem solid white",
  display: "inline-block"
})

const arrowCircle = mergeStyles({
  lineHeight: "1.375rem",
  paddingLeft: "0.55rem",
  boxSizing: "border-box",
  textAlign: "left",
  display: "inline-block", height: "1.5rem", width: "1.5rem", borderRadius: "0.75rem"
})
const arrowCircleWhite = mergeStyles(arrowCircle, {

  backgroundColor: "white",
  [lessThanMedium]: {
    paddingLeft: "0.35rem",
    paddingTop: "0.25rem",
  }
})
const arrowCircleRed = mergeStyles(arrowCircle, {
  backgroundColor: red
})

function ArrowRight(props: { alt?: boolean, className?: string }) {
  return <span className={mergeStyles(props.alt ? arrowCircleWhite : arrowCircleRed, props.className)}>
    <span className={mergeStyles(props.alt ? arrowRightRed : arrowRightWhite)} />
  </span>
}


const spaceEvenly = mergeStyles({ display: "flex", justifyContent: "space-evenly", ">*": { flexBasis: "10rem", flexGrow: "1", boxSizing: "border-box" } });

function makeSchedule(colorArray: { text: string, bgColor: string }[], itm1: string, colorIdx: number, itm2?: string, colorIdx2?: number) {
  const output = [{ text: itm1, color: colorArray[colorIdx].bgColor, textColor: colorArray[colorIdx].text }];
  if (itm2 && colorIdx2 !== undefined)
    output.push({ text: itm2, color: colorArray[colorIdx2].bgColor, textColor: colorArray[colorIdx2].text })
  return output;
}




type Product = {
  name: string,
  color: string,
  logo: string,
  bg: string,
  colorArr?: ({ bgColor: string, text: string })[]
  overview: string[],
  phases: { title: string, subtitle: string, text: string }[]
  scenario: { text: string[], cloud: string }
  simulation?: { title: string, text: ReactNode }[],
  schedule?: ({ text: string, color: string, textColor: string }[])[]
};


const bsColorArr = [
  { bgColor: "#c55a11", text: "white" },
  { bgColor: "#f5b09d", text: "black" },
  { bgColor: "#fbe0d9", text: "black" }];

const psColorArr = [
  { bgColor: "#ffd44b", text: "black" },
  { bgColor: "#ffe9a3", text: "black" },
  { bgColor: "#fffbef", text: "black" }];

const products: Record<"bs" | "kg" | "ps", Product> = {
  bs: {
    name: "Baltic Storm",
    color: "#c55a11",
    logo: logoBS,
    bg: bgBS,
    colorArr: bsColorArr,
    overview:
      ["Baltic Storm is a sixteen-day international crisis simulation.  Set in the near future, it highlights the resurgence of Russia as a threat to international stability and to the internal cohesion of the NATO alliance.",
        "The simulation will give you a sense of how the US intelligence community works, the roles that the different sources of intelligence play in monitoring events and predicting developments, and of how the US national security apparatus functions during a crisis."],
    phases: [
      { title: "Phase I: Two-Week", subtitle: "Intelligence Simulation", text: "Play the role of intelligence analyst attempting to synthesize overwhelming information into concise intelligence reports" },
      { title: "Phase II: Two-Day", subtitle: "Dynamic Wargame", text: "Play the role of policymaker attempting to achieve strategic goals in a rapidly shifting environment" }],
    scenario: {
      text:
        [
          'The strategic landscape is challenging and complicated.  Global political trends that we see today have accelerated: Russia has reemerged as a global power and, despite major investments, US global leadership has continued to falter.',
          'Externally, NATO remains a unified force, but internally the Alliance is split between three blocks, each with divergent interests: "NATO East", comprising the continental European NATO partners; “NATO West”, comprising the UK and Canada; and the United States.  While these factions are generally committed to overcoming their differences, strain within the Alliance is growing.',
          'In May, ethnic tensions in Latvia—a NATO member state—boil over as Russian minorities clash with the Latvian National Guard.  Will Russia choose this moment to challenge NATO and back the protestors?  Can NATO stand together in the face of a direct Russian challenge?'
        ], cloud: cloudBS
    },
    simulation: [
      { title: "Bootcamp", text: "Baltic Storm begins with a two-day bootcamp, led by an intelligence expert with over 10 years in the field.  You’ll learn intelligence-style writing and briefing, acquire tools for data analysis, and practice structured analytic techniques through hands on exercises and group discussions." },
      { title: "Intelligence Simulation", text: "The intelligence simulation is the core of Baltic Storm.  You’ll play the role of an analyst, reviewing hundreds of simulated text-based intelligence reports and integrating your information into the bigger picture that is emerging to your team.  During the phase of Baltic Storm, you team will work together to produce two intelligence reports that you’ll brief to a simulated panel of defense policymakers." },
      { title: "Wargame", text: "Baltic Storm concludes with a two-day, turn-based wargame.  Using the Waymark gaming engine, you’ll directly control your team’s military and policy as you pursue your strategic goals.  The game is completely dynamic, reset on each turn based on YOUR actions." }],
    schedule: [
      makeSchedule(bsColorArr, "Bootcamp", 0, "Day 1 Intelligence Drop", 2),
      makeSchedule(bsColorArr, "Bootcamp", 0, "Day 2 Intelligence Drop", 2),
      makeSchedule(bsColorArr, "Days 3 and 4 Intelligence Drops", 2),
      makeSchedule(bsColorArr, "Days 5 and 6 Intelligence Drops", 2, "Collection Opportunity", 1),
      makeSchedule(bsColorArr, "Days 7 and 8 Intelligence Drops", 2),
      makeSchedule(bsColorArr, "Day 9 Intelligence Drop", 2, "Collection Opportunity", 1),
      makeSchedule(bsColorArr, "Day 10 Intelligence Drop", 2),
      makeSchedule(bsColorArr, "Team Briefing", 0, "Day 11 Intelligence Drop", 2),
      makeSchedule(bsColorArr, "Day 12 Intelligence Drop", 2),
      makeSchedule(bsColorArr, "Days 13 and 14 Intelligence Drops", 2),
      makeSchedule(bsColorArr, "Days 15 and 16 Intelligence Drops", 2, "Collection Opportunity", 1),
      makeSchedule(bsColorArr, "Days 17 and 18 Intelligence Drops", 2),
      makeSchedule(bsColorArr, "Days 19 Intelligence Drops", 2, "Collection Opportunity", 1),
      makeSchedule(bsColorArr, "Day 20 Intelligence Drop", 2, "Team Briefing", 0),
      makeSchedule(bsColorArr, "Wargame Rounds 1 and 2", 0),
      makeSchedule(bsColorArr, "Wargame Rounds 3 and 4", 0)
    ]



  },
  kg: {
    name: "King's Gambit",
    //color: "#362367",
    color: "#2c54b4",
    logo: logoKG,
    bg: bgBS,
    overview:
      ["King’s Gambit is a three day wargame.  Set near the end of the decade, the game focuses on the Korean Peninsula, and on the intersection of US, Chinese, and Korean interests in the region.",
        "The simulation will give you an understanding of the complex realities that continue to make the Korean Peninsula one of the most challenging quagmires in international relations."],
    phases:
      [{ title: "Three-Day", subtitle: "Dynamic Wargame", text: "Play the role of policymaker attempting to achieve strategic goals in a rapidly shifting environment." }],
    scenario: {
      cloud: cloudKG,
      text:
        ['October, 2029. Despite the world’s best efforts, the situation on the Korean Peninsula remains challenging and complicated.  The Kim regime has held the world at bay for over a decade with a small but capable nuclear arsenal that can threaten the US and Europe.',
          'One foggy morning, a South Korean Navy ship, on routine patrol, suffers a catastrophic explosion and sinks, killing nearly all on board.  Suddenly, the tense but stable scenario on the Peninsula erupts into the hottest flashpoint in recent memory.',
          'As the crisis unfolds, the United States, China, North and South Korea find themselves entangled in the King’s Gambit.']
    }

  },
  ps: {
    name: "Persian Sword",
    color: "#786621",
    colorArr: psColorArr,
    logo: logoPS,
    bg: bgBS,
    overview:
      ["Persian Sword is a sixteen-day policy simulation focused on the threat that a nuclear Iran poses to Israel and the west and the challenges associated with meaningful non-proliferation negotiations.",
        "The simulation enables participants to investigate the various interests and actors involved in the nuclear debate, to understand the impacts of uncertainty on non-proliferation issues, and to understand the interplay of long and short term policies in international negotiations."
      ],
    phases: [
      { title: "Phase I: Two-Week", subtitle: "Policy Simulation", text: "Negotiate with your adversaries and implement long term policy as a key strategic deadline approaches" },
      { title: "Phase II: Two-Day", subtitle: "Dynamic Wargame", text: "React to rapid developments as the negotiation deadline passes, and pursue your policy goals in a dangerous new environment." }],
    scenario: {
      text:
        [
          'A critical 10-year agreement between Iran and the West has nearly run its course.  This agreement, while imperfect, has kept the nuclear question in the Middle East in a tense but stable statis.  As the deadline approaches, rumors begin to circulate that Iran’s nuclear program never halted, secretly moving underground.  Some, including Mossad, estimate that Iran is merely months away from testing a nuclear weapon.  But how reliable are these reports? How should the US and Israel respond?',
          'In Iran, the future is even more muddled.  How hard should Iran press for a new deal?  Where does Russia—Iran’s long term strategic ally—stand?  What role will Saudi Arabia—Iran’s long term strategic rival—play in the coming crisis?',
          'The world has four months before the clock reaches zero.  What happens next?'
        ], cloud: cloudPS
    },
    simulation: [
      { title: "Bootcamp", text: "Persian Sword begins with a four hour bootcamp on “bottom line up front” writing and briefing.  This writing style, standard in the US intelligence community, focused on putting the most critical information first in a succinct and condensed way." },
      { title: "Policy Simulation", text: "The core of Persian Sword is the policy simulation.  Over the course of two weeks, you’ll have multiple opportunities to negotiate with your adversaries, implement strategic policies, and utilize the tools of your government to gain additional insight and information into your opponents goals, plans, and actions.  The policy simulation emphasizes long term policy." },
      { title: "Wargame", text: "Persian Sword concludes with a two-day, turn-based wargame. Using the Waymark gaming engine, you’ll directly control your team’s military and policy as you pursue your strategic goals.  The game is completely dynamic, reset on each turn based on YOUR actions." }],
    schedule: [
      makeSchedule(psColorArr, "Bootcamp", 0),
      makeSchedule(psColorArr, "Round 1 Policy and Negotiation", 0),
      makeSchedule(psColorArr, "Round 2 Situational Update", 2),
      makeSchedule(psColorArr, "Collection Opportunity", 1),
      makeSchedule(psColorArr, "Round 2 Policy and Negotiation", 0),
      makeSchedule(psColorArr, "Round 3 Situational Update", 2),
      makeSchedule(psColorArr, "Collection Opportunity", 1),
      makeSchedule(psColorArr, "Team Briefing", 0),
      makeSchedule(psColorArr, "Round 3 Policy and Negotiation", 0),
      makeSchedule(psColorArr, "Round 4 Situational Update", 2),
      makeSchedule(psColorArr, "Collection Opportunity", 1),
      makeSchedule(psColorArr, "Round 4 Policy and Negotiation", 0),
      makeSchedule(psColorArr, "Round 5 Situational Update", 2),
      makeSchedule(psColorArr, "Collection Opportunity", 1, "Team Briefing", 0),
      makeSchedule(psColorArr, "Wargame Rounds 5 and 6", 0),
      makeSchedule(psColorArr, "Wargame Rounds 7 and 8", 0),
    ]

  }
}


const fadeIn = mergeStyles({
  animationName: keyframes({
    from: {
      opacity: 0.5
    },
    to: {
      opacity: 1
    }
  }),
  animationDuration: "0.75s",
  animationFillMode: "both"
})
const fadeInFast = mergeStyles({
  animationName: keyframes({
    from: {
      opacity: 0.5
    },
    to: {
      opacity: 1
    }
  }),
  animationDuration: "0.5s",
  animationFillMode: "both"
})

const fadeIn2 = mergeStyles({
  animationName: keyframes({
    from: {
      opacity: 0.5
    },
    to: {
      opacity: 1
    }
  }),
  animationDuration: "0.75s",
  animationFillMode: "both"
})

type ProductsKey = keyof typeof products;


function ScheduleItem(props: { day: number, productColor: string, items: { text: string, color: string, textColor: string }[] }) {
  return <div className={mergeStyles({ display: "flex", flexDirection: "column" })}>
    <div
      className={mergeStyles(obviBold, {
        backgroundColor: "rgba(0,0,0,0.6)",
        color: props.productColor,
        border: "1px solid " + gray,
        padding: "0.5rem"
      })}
    >Day {props.day}</div>
    <div
      className={mergeStyles({
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignContent: "stretch",
        ">*": {
          flexBasis: "50%",
          flexGrow: 1,
          border: "1px solid " + gray
        }
      })}
    >
      {props.items.map((itm) => {
        return <div className={mergeStyles(textWhite, textCenter, { padding: "0.5rem", backgroundColor: itm.color, color: itm.textColor, display: "flex", justifyContent: "center", alignItems: "center" })}>
          {itm.text}
        </div>
      })}</div>
  </div>
}

function ProductSchedule(props: { product: Product }) {
  const { product } = props;
  return product.schedule && <div className={mergeStyles({
    overflow: "auto", alignItems: "center", flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    ">*": {
      flexBasis: "calc(100%/7)",
      height: "11rem",
    },
    margin: "0 15%",
    [lessThanMedium]: {
      margin: "0 0.25rem",
      alignContent: "start",
      ">*": {
        flexBasis: "calc(100%/3)",
      }
    }
  })}>
    {product.schedule.map((s, idx) => {
      return <ScheduleItem items={s} productColor={product.color} day={idx + 1} />
    })}
    <div className={mergeStyles(blackBox, {
      flexGrow: "1", display: "flex", boxSizing: "border-box",
      [lessThanMedium]: {
        flexBasis: "100%"
      }

    })} >
      <div className={mergeStyles(largeFont, { flexBasis: "10rem", flexGrow: 4, boxSizing: "border-box", display: "flex", alignItems: "center", padding: "0 2rem" })}>
        {product.name} can be played virtually or in person based on your organization’s needs and interests
      </div>
      <div className={mergeStyles({ flexBasis: "10rem", flexGrow: 1, display: "flex", flexDirection: "column", boxSizing: "border-box", padding: "1rem" })} >
        <div className={mergeStyles({ color: product.colorArr?.[0].text, backgroundColor: product.colorArr?.[0].bgColor, display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid " + gray, flexGrow: 1 })} >Formal Meetings</div>
        <div className={mergeStyles({ color: product.colorArr?.[1].text, backgroundColor: product.colorArr?.[1].bgColor, display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid " + gray, flexGrow: 1 })}>Team Deadline</div>
        <div className={mergeStyles({ color: product.colorArr?.[2].text, backgroundColor: product.colorArr?.[2].bgColor, display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid " + gray, flexGrow: 1 })}>Independent Effort</div>
      </div>
    </div>
  </div> || null
}


function ProductOverview(props: { product: Product }) {
  const { product } = props;
  return <div className={mergeStyles(spaceEvenly, blockWhenSmall, { alignItems: "center", flexGrow: 1, overflow: "auto" })}>
    <div className={pad2}>
      <div className={mergeStyles(pad1, obvia, xLargeFont, textBold, textWhite, { backgroundColor: product.color })}>{product.name}</div>
      <div className={mergeStyles(grayBox, largeFont, { ">p": { marginTop: 0 }, padding: "2rem" })}>
        {product.overview.map(o => <p>{o}</p>)}
      </div>
    </div>
    <div className={mergeStyles(textWhite, textCenter, { padding: "4rem", textShadow: "0 0 8px #000000" })}>
      {
        product.phases.map((p, i, arr) => {
          return <div className={(i === (arr.length - 1)) ? mergeStyles({ paddingTop: "1rem", borderTop: "0.25rem solid " + product.color }) : undefined}>
            <div className={mergeStyles(xLargeFont, textBold)}>{p.title}</div>
            <div className={mergeStyles(xLargeFont, textBold)}>{p.subtitle}</div>
            <div className={mergeStyles(largeFont, textItalics, { margin: "2rem 0" })}>{p.text}</div>
          </div>
        })
      }
    </div>
  </div>

}






function ProductScenario(props: { product: Product }) {
  const { product } = props;
  return <div className={mergeStyles(spaceEvenly, blockWhenSmall, { overflow: "auto", alignItems: "center", flexGrow: 1 })}>
    <div className={mergeStyles(textWhite, mediumFont, imgCover, bgImage(product.scenario.cloud), {
      margin: "0 8rem", padding: "4rem",
      [lessThanMedium]: {
        margin: "0 1rem",
        padding: "1rem"
      }
    })}>
      <div className={mergeStyles(textCenter, pad2, blackBox)}>
        {product.scenario.text.map((t) => {
          return <p>{t}</p>
        })}
      </div>
    </div>
  </div>
}


function SimCircle(props: { children: string, small?: boolean, color: string }) {
  const pct = props.small ? "45%" : "55%"
  const circleBorder = "0.5rem solid " + props.color;
  return <div className={mergeStyles({
    border: circleBorder, borderRadius: "50%", width: pct, position: "relative", paddingTop: pct, margin: "auto",
    [lessThanMedium]: {
      width: "100%",
      paddingTop: "100%"
    }
  })}>
    <div className={mergeStyles(obvia, largeFont, textBold, textCenter, { backgroundColor: "rgba(200,200,200,0.5)", display: "flex", justifyContent: "center", alignItems: "center", border: circleBorder, borderRadius: "50%", width: "85%", height: "85%", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 })}>
      {props.children}
    </div>
  </div>
}

const prodSimText = mergeStyles({
  textAlign: "justify",
  textShadow: "0 0 8px #000000"
})

function ProductSimulation(props: { product: Product }) {
  const { product } = props;
  return product.simulation && <div className={
    mergeStyles(textWhite, mediumFont, spaceEvenly, blockWhenSmall,
      {
        overflow: "auto",
        alignItems: "center",
        flexGrow: 1,
        margin: "0 4rem",
        ">*": {
          margin: "0 3rem",
          position: "relative"
        },
        [lessThanMedium]: {
          margin: "0"
        }
      })}>
    <div className={mergeStyles({})}>
      <SimCircle small color={product.color}>{product.simulation[0].title}</SimCircle>
      <div className={prodSimText}>{product.simulation[0].text}</div>
    </div>
    <div className={mergeStyles({ position: "relative" })}>
      <SimCircle color={product.color}>{product.simulation[1].title}</SimCircle>
      <div className={prodSimText}>{product.simulation[1].text}</div>
    </div>
    <div className={mergeStyles({ position: "relative" })}>
      <SimCircle small color={product.color}>{product.simulation[2].title}</SimCircle>
      <div className={prodSimText}>{product.simulation[2].text}</div>
    </div>
  </div> || null
}


function ContactPage() {
  const location = useLocation();
  const prm = new URLSearchParams(location.search);
  const productCode = prm.get("p");
  const [product, setProduct] = useState(productCode);
  const prodName = products[product as ProductsKey]?.name
  //const [formData, setFormData] = useState<{ firstName?: string, lastName?: string, phone?: string, email?: string, subject?: string, details?: string }>({
  // subject: `${product || "General"} Inquiry`
  //})
  //const product = productCode ? products[productCode] : undefined;
  const hist = useHistory();
  const closePopup = (ev: React.MouseEvent) => {
    if (ev.target === ev.currentTarget) {
      hist.push(location.pathname)
    }
  };

  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  return <div
    className={mergeStyles(bgGray, spaceEvenly, blockWhenSmall, {
      height: "calc(100vh - 4.5rem)", width: "100%",
    })}>
    <div className={mergeStyles(textWhite, obvia, textBold, { fontSize: "4rem", display: "flex", alignItems: "center", justifyContent: "center" })} >
      <div className={mergeStyles({ [lessThanMedium]: { paddingBottom: "1.5rem" } })} style={{ width: "80%" }}>Request More Information.<br className={hideWhenSmall} /><br className={hideWhenSmall} /><br className={hideWhenSmall} /></div></div>
    <form
      ref={formRef}
      action="https://portal.waymarkintel.com/api/mailer/form"
      //action="http://localhost:1337/api/mailer/form"
      method="post"
      // onSubmit={async (ev) => {

      // }}
      className={mergeStyles({
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        flexWrap: "wrap",

        ">label": {
          flexBasis: "50%",
          paddingRight: "2rem",
          marginBottom: "1rem",
          boxSizing: "border-box",
          color: "white",
          fontFamily: "obvia, sans-serif",
          height: "5rem",
          [lessThanMedium]: {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem"

          }
        },
        ">button": {
          flexBasis: "50%",
          paddingRight: "2rem",
          marginBottom: "1rem",
          boxSizing: "border-box",
          color: "white",
          fontFamily: "obvia, sans-serif",
          height: "5rem"
        },
        ">label>input, >label>select, >label>textarea": {
          display: "block",
          width: "100%",
          padding: "1rem",
          boxSizing: "border-box",
          borderRadius: "0.5rem",
          outline: "none",

        },
        ">label>input:focus": {
          backgroundColor: lightBlue
        }

      })}>
      <label>First Name*<input name="firstName" type="text" maxLength={500} /></label>
      <label>Last Name*<input name="lastName" type="text" maxLength={500} /></label>
      <label>Email*<input name="email" type="text" maxLength={500} /></label>
      <label>Phone<input name="phone" type="text" maxLength={500} /></label>
      <label>Product<select name="product" onChange={(ev) => {
        setProduct(ev.currentTarget.selectedOptions[0].value || "");
      }}>
        <option>No Specific Product</option>
        {Object.keys(products).map((t) => {
          return <option selected={t === prodName} value={t} >{products[t as ProductsKey].name}</option>
        })}
      </select></label>
      <label>How Can We help?
        <select name="subject" >
          <option value={`${prodName || "General"} Inquiry`} >{`${prodName || "General"} Inquiry`}</option>
          {prodName &&
            [
              `How Does ${prodName} Work?`,
              `Can My Orgnization Play ${prodName}`,
              `Can ${prodName} be customized`,
              `Can I get a quote for ${prodName}`
            ].map((o) => <option value={o}>{o}</option>)
          }
        </select>


      </label>
      <label style={{ flexBasis: "100%", display: "block", height: "10rem" }} >
        Additional Information
        <textarea name="details" maxLength={2000} style={{ resize: "none", fontFamily: "sans-serif", height: "calc(100% - 1rem)" }} />
      </label>
      <label style={{ display: "block", alignItems: "flex-end", marginLeft: "auto", flexBasis: "unset" }}>
        <input

          className={mergeStyles({
            ":disabled": {
              backgroundColor: lightBlue,
              color: "black"
            }

          })}
          disabled={disabledSubmit} type="button" value={isFormSent ? "We'll Be In Touch!" : (disabledSubmit ? "Sending" : "Submit")} onClick={async (ev) => {
            if (formRef.current) {
              if (!disabledSubmit) {
                setDisableSubmit(true);
                const form = formRef.current;
                const formData = new FormData(form);
                const body: Record<string, string> = {}
                formData.forEach((v, k) => {
                  body[k] = v as string;
                });
                try {
                  const resp = await fetch(form.action, {
                    headers: {
                      "content-type": "application/json"
                    },
                    method: form.method,
                    body: JSON.stringify(body)
                  });
                  if (!resp.ok)
                    throw {};
                  setIsFormSent(true);
                }
                catch (ex) {
                  setDisableSubmit(false);
                  alert("Something went wrong...");
                }

              }
              ev.preventDefault();
              ev.stopPropagation();
            }
            ev.preventDefault();
            ev.stopPropagation();
          }} />
      </label>
    </form>

  </div >


}

function ProductPopup() {
  const location = useLocation();
  const prm = new URLSearchParams(location.search);
  const productCode = prm.get("product") as ProductsKey | undefined;
  const pageCode = prm.get("page") as "scenario" | "simulation" | "schedule" | undefined;

  const product = productCode ? products[productCode] : undefined;
  const hist = useHistory();
  const closePopup = (ev: React.MouseEvent) => {
    if (ev.target === ev.currentTarget) {
      hist.push(location.pathname)
    }
  };

  function makeLinkProps(page?: string) {
    return {
      href: `${location.pathname}?product=${productCode}${page ? `&page=${page}` : ''}`,
      className: page === pageCode ? "current-link" : undefined
    };

  }

  return product ? <div className={mergeStyles({ zIndex: 100000, display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", height: "100vh", width: "100vw", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.35)" })}
    onClick={closePopup}>
    <div
      className={mergeStyles(fadeInFast, imgCover, bgImage(product.bg), {
        display: "flex", flexDirection: "column", backgroundColor: "white", boxShadow: shadow, position: "absolute", height: "90%", width: "90%",

        [lessThanMedium]: {
          width: "100%",
          height: "100%"
        }

      })}>
      <div className={mergeStyles(bgGray, textWhite, obvia, largeFont,
        {
          borderBottom: "0.5rem solid " + product.color, paddingLeft: "8rem", display: "flex", alignItems: "center",
          ">a": {
            padding: "1rem", color: "white", textDecoration: "none",
            [lessThanMedium]: {
              display: "none"
            }

          },
          ">a.current-link": { fontWeight: "bold" },
          [lessThanMedium]: {
            paddingLeft: "4rem"
          }
        })}>
        {/* <WebLink className={mergeStyles({ color: product.color  })} >{product.name}</WebLink> */}
        <WebLink {...makeLinkProps()}>Overview</WebLink>
        <WebLink  {...makeLinkProps("scenario")} >Scenario</WebLink>
        {product.simulation && <WebLink  {...makeLinkProps("simulation")}>Simulation</WebLink>}
        {product.schedule && <WebLink {...makeLinkProps("schedule")}  >Schedule</WebLink>}
        <WebLink onClick={closePopup} href={`/contact?p=${productCode}`} className={mergeStyles({ marginLeft: "auto" })}>Request More Information</WebLink>

        <select

          onChange={(ev) => {
            hist.push(ev.target.selectedOptions[0].value)
          }}
          className={mergeStyles({
            padding: "0.75rem", margin: "0.75rem", width: "calc(100%)", display: "none", [lessThanMedium]: {
              display: "unset"
            }

          })}>
          {["Overview", "Scenario", "Simulation", "Schedule"].filter(
            t => product[t.toLowerCase() as keyof Product]).map((t, idx) => <option selected={idx === 0 && !pageCode || t.toLowerCase() === pageCode} value={makeLinkProps(idx ? t.toLowerCase() : undefined).href}>{`${product.name}: ${t}`}</option>)}
        </select>
        <button
          onClick={closePopup}
          className={mergeStyles(imgSingle, bgRed, bgImage(xbutton), {
            outlineWidth: "0",
            marginLeft: "2rem",
            marginRight: "1rem",
            border: `2px solid ${darkRed}`,
            backgroundSize: "65%",
            height: "2rem",
            width: "2rem",
            cursor: "pointer",
            [lessThanMedium]: {
              marginLeft: "0.5rem"
            }
          })}
        />
      </div>
      <div className={mergeStyles(imgCover, bgImage(product.logo), {
        width: "8rem", height: "8rem", position: "absolute", top: "0.5rem", left: "0.5rem",
        [lessThanMedium]: {
          width: "3.5rem",
          height: "3.5rem"
        }

      })} />
      {!pageCode && <ProductOverview product={product} />}
      {pageCode === "scenario" && <ProductScenario product={product} />}
      {pageCode === "simulation" && <ProductSimulation product={product} />}
      {pageCode === "schedule" && <ProductSchedule product={product} />}
    </div>
  </div> : null

}


const colWhenSmall = mergeStyles({
  [lessThanMedium]: {
    flexDirection: "column"
  }
});
const blockWhenSmall = mergeStyles({
  [lessThanMedium]: {
    display: "block"
  }
});

const hideWhenSmall = mergeStyles({
  [lessThanMedium]: {
    display: "none"
  }
});


const revColWhenSmall = mergeStyles({
  [lessThanMedium]: {
    flexDirection: "column-reverse"
  }
});

const wideWhenSmall = mergeStyles({
  [lessThanMedium]: {
    paddingTop: "75%"
  }
});

function HomePage() {
  const [hoverState, setHoverState] = useState<keyof typeof products>()

  return <>
    <Banner />

    <div className={mergeStyles(spaceEvenly, colWhenSmall)}>
      <div className={mergeStyles(hoverState ? fadeIn : fadeIn2, {
        backgroundColor: hoverState ? products[hoverState].color : red,
        color: "white"
      }, obvia, largeFont, {
        float: "right", boxSizing: "border-box", padding: "0 2rem",
        [lessThanMedium]: {
          paddingBottom: "2rem"
        }

        /*fontStyle:"italic",*/
      })}>
        {!hoverState && <div>
          <div className={mergeStyles(textCenter, obvia, xLargeFont, { color: "white", margin: "2rem 0 1rem 0", paddingBottom: "0.5rem", fontWeight: "bold" /*borderBottom: "0.125rem solid #ccc"*/ })}>The Mission</div>
          <span>Waymark Intelligence Solutions is dedicated to equipping the next generation of national security analysts and decision makers with the practical skills required to lead on day one.</span>
        </div>}
        {hoverState && <div>
          <div className={mergeStyles(textCenter, obvia, xLargeFont, { color: "white", margin: "2rem 0 1rem 0", paddingBottom: "0.5rem", fontWeight: "bold" /*borderBottom: "0.125rem solid #ccc"*/ })}>{products[hoverState].name}</div>
          <span>{products[hoverState].overview[0]}</span>
        </div>}
        <div className={mergeStyles({ textAlign: "right" }, {
          marginTop: "0.5rem", marginBottom: "2.5rem", [lessThanMedium]: {
            display: "none"
          }
        })}>
          <span className={mergeStyles({ marginRight: "0.5rem", verticalAlign: "middle" })} >{hoverState ? "Click Logo For Details" : "Learn more about our products."}</span>
          <ArrowRight alt />
        </div>
      </div>
      <div className={mergeStyles({
        float: "left", position: "relative", borderBottom: "1px solid #ccc", backgroundColor: "#fefefe", display: "flex", flexDirection: "column",
        [lessThanMedium]: {
          paddingBottom: "2rem"
        }

      })}>
        {/* <div className={mergeStyles(imgCover, bgImage(jets), { position: "absolute", height: "100%", width: "100%", top: 0, left: 0, filter: "blur(2px)" })}/> */}
        <div className={mergeStyles(textCenter, obvia, xLargeFont, textRed, { marginTop: "2rem", fontWeight: "bold" })}>Our Products</div>
        <div className={mergeStyles({ display: "flex", justifyContent: "space-evenly", padding: "0rem 2rem", flexGrow: 1 })}>
          {(["bs", "ps", "kg"] as ProductsKey[]).map((k) => {
            return <WebLink href={`?product=${k}`} className={mergeStyles(imgContain, bgImage(products[k].logo), productClass)}
              onMouseOver={() => setHoverState(k)}
              onMouseOut={() => setHoverState(undefined)}
            />
          })}
        </div>

      </div>
    </div>

    <div className={mergeStyles(spaceEvenly, colWhenSmall)}>
      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(expert))} />
      <div className={mergeStyles({ clear: "both", paddingTop: "1rem", backgroundColor: beige })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { paddingLeft: "2rem", marginTop: "2rem", fontWeight: "bold" })}>Why Waymark?</div>
        <ul className={mergeStyles(obvia, mediumFont, { listStyleType: "none", margin: "2rem 0rem", lineHeight: "2" })}>
          <li><ArrowRight className={mergeStyles({ marginRight: "0.5rem" })} />The national security problem has changed.  </li>
          <li><ArrowRight className={mergeStyles({ marginRight: "0.5rem" })} />Front line analysts aren’t well equipped for today’s environment.  </li>
          <li><ArrowRight className={mergeStyles({ marginRight: "0.5rem" })} />Our simulations deliver the practical skills you need to be ready.  </li>
        </ul>
        <WebLink href="/why-waymark" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", marginRight: "2rem", height: "3rem", width: "3rem" })} />
      </div>

    </div>

    <div className={mergeStyles(spaceEvenly, revColWhenSmall)}>
      <div className={mergeStyles({ padding: "0 2rem", position: "relative" })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { margin: "2rem 0", fontWeight: "bold" })}>Intelligence Simulations</div>
        <span className={mergeStyles(obvia, mediumFont)}><b>Waymark intelligence simulations take you inside the highly secretive world of intelligence analysis. </b> Work as a team to triage overwhelming amounts of information, apply intelligence tradecraft, and brief assessments to decision makers during these intense one-to-two week simulations.</span>
        <div className={mergeStyles(obvia, largeFont, textCenter, textRed, { fontWeight: "bold", padding: "1rem" })}>Realistic. Complex. Immersive.</div>
        <WebLink href="/intelligence-simulations" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", height: "3rem", width: "3rem" })} />
      </div>

      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(intelsim))} />
    </div>

    <div className={mergeStyles(spaceEvenly, colWhenSmall)}>
      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(polsim))} />
      <div className={mergeStyles({ padding: "0 2rem" })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { margin: "2rem 0", fontWeight: "bold" })}>Policy Simulations</div>
        <span className={mergeStyles(obvia, mediumFont)}><b>Waymark policy simulations give participants a seat at the table where big decision area made and grand strategies are born. </b> Pursue your strategic objectives, negotiate with friends and foes, and utilize all aspects of state power in these interactive one-to-three week simulations.</span>
        <div className={mergeStyles(obvia, largeFont, textCenter, textRed, { fontWeight: "bold", padding: "1rem" })}>Dynamic. Holistic. Immersive.</div>
        <WebLink href="/policy-simulations" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", height: "3rem", width: "3rem" })} />
      </div>
    </div>


    <div className={mergeStyles(spaceEvenly, revColWhenSmall)}>
      <div className={mergeStyles({ padding: "0 2rem" })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { margin: "2rem 0", fontWeight: "bold" })}>Wargames</div>
        <span className={mergeStyles(obvia, mediumFont)}><b>Waymark wargames—based on scenarios torn from tomorrow’s headlines—plunge participants into the chaotic world of crisis policy.</b>  React to your adversaries moves, pursue tactical and strategic objectives, and utilize all aspects of state power in these dynamic one-to-three day simulations.</span>
        <div className={mergeStyles(obvia, largeFont, textCenter, textRed, { fontWeight: "bold", padding: "1rem" })}>Dynamic. Exciting. Immersive.</div>
        <WebLink href="/wargames" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", height: "3rem", width: "3rem" })} />
      </div>
      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(ship))} />
    </div>

    <div className={mergeStyles(spaceEvenly, colWhenSmall)}>
      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(bootcamps))} />
      <div className={mergeStyles({ padding: "0 2rem" })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { margin: "2rem 0", fontWeight: "bold" })}>Bootcamps</div>
        <span className={mergeStyles(obvia, mediumFont)}><b>Waymark bootcamps deliver cutting edge analytic skills and techniques, customized to your organization’s needs.</b>  Learning can be either virtual or in person.</span>
        <div className={mergeStyles(obvia, largeFont, textCenter, textRed, { fontWeight: "bold", padding: "1rem" })}>Taught by experts. Practical. Customizable.</div>
        <WebLink href="/bootcamps" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", height: "3rem", width: "3rem" })} />
      </div>
    </div>


    <div className={mergeStyles(spaceEvenly, revColWhenSmall)}>

      <div className={mergeStyles({ padding: "0 2rem" })}>
        <div className={mergeStyles(obvia, xLargeFont, textRed, { margin: "2rem 0", fontWeight: "bold" })}>Gaming Engine</div>
        <span className={mergeStyles(obvia, mediumFont)}><b>Waymark’s one of a kind gaming engine delivers an immersive simulation experience to participants.</b>  Coordinate your strategy, craft your policies, and move your military all within our virtual portal.</span>
        <div className={mergeStyles(obvia, largeFont, textCenter, textRed, { fontWeight: "bold", padding: "1rem" })}>Move. Monitor. Collect. Attack.</div>
        <WebLink href="/gaming-engine" className={mergeStyles(imgContain, bgImage(elipsis), { cursor: "pointer", ":hover": { opacity: 1 }, opacity: "0.25", position: "relative", float: "right", marginBottom: "1rem", height: "3rem", width: "3rem" })} />
      </div>
      <div className={mergeStyles(wideWhenSmall, imgCover, bgImage(gameengine))} />
    </div>
  </>
}





const obviBold = mergeStyles(obvia, textRed, textCenter, textBold);
function BoldThenText(props: { bold: string, children: string }) {
  return <>
    <span className={obviBold}>{props.bold}</span><br />
    {props.children}
  </>
}
const pad1 = mergeStyles({
  padding: "1rem"
})
const pad2 = mergeStyles({
  padding: "2rem"
})



const whyWayInfo = [
  {
    title: "The national security problem has changed.",
    text: <>The advent of new technologies and the proliferation of social media has fundamentally changed the national security problem.  For decades, the challenge facing the intelligence and defense communities was interpreting meaning from limited information.  <span className={mergeStyles(textRed, textBold)}>Today, our national security professionals are struggling to process overwhelming amounts of information to deliver decision advantage to warfighters and policymakers.</span></>,
    quoteText: '"This is a data world, not a world of data scarcity, but data abundance…the data available should make us [excited], but only if we can command it in a way that is not the manual processes that have dominated our past."',
    quoteBy: "Susan Gordan, former Deputy Director, National Intelligence"
  },
  {
    title: "Front line analysts aren’t well equipped for today’s environment.",
    text: <><span className={mergeStyles(textRed, textBold)}>Traditional training programs must be augmented with practical experience to produce a front line workforce that is ready on day one.</span> While a strong background in case study, qualitative research, and strategic level decision making remains critical, those skills alone won’t position your students or your workforce to succeed in today’s data-centric environment.</>,
    quoteText: '"We’re collecting more data today than we can effectively process.  What we process we struggle to make sense of and that which we make sense of we can’t effectively disseminate across a global enterprise to ensure that it gets to the decision maker."',
    quoteBy: "Lt. Gen. Vincent Stewart, former Director, Defense Intelligence Agency"
  },
  {
    title: "Our simulations deliver the practical skills you need to be ready.",
    text: <><span className={mergeStyles(textRed, textBold)}>Waymark’s immersive simulations teach the practical skills that front-line analysts and aides need to be successful in today’s national security environment.</span>Waymark programs can help your educational and professional organizations set students apart in the increasingly competitive national security space.  Our immersive simulations can give students and young professionals the tools they need to succeed.</>,
    quoteText: '"We’re at our own version of D-Minus One in a world that has moved from data scarcity to data abundance, from hunting for that one perfect image to creating coherence from a flood of images and the services that follow."',
    quoteBy: "Robert Cardillo, former Director, National Geospatial-Intelligence Agency"
  }
]

function WhyWaymarkPage() {
  const [index, setIndex] = useState(0);
  const curWhy = whyWayInfo[index];

  const [curTimeout, setCurTimeout] = useState<any>();

  useEffect(() => {
    setCurTimeout(setTimeout(() => {
      setIndex((index + 1) % (whyWayInfo.length))
    }, 4000))
  }, [index, setIndex, setCurTimeout])

  return <div>
    <SubPageBanner title="Why Waymark?" imageUrl={way2}
      imageClass={mergeStyles({
        [lessThanMedium]: {
          overflow: "hidden",
          position: "relative"
        }
      })}
      imageContent={
        <><div className={mergeStyles({
          border: "1px solid black", color: "white", backgroundColor: "rgba(0,0,0,0.7)", width: "80%", margin: "auto", boxShadow: shadow, padding: "2rem",
          [lessThanMedium]: {
            boxSizing: "border-box",
            width: "calc(100% - 2rem)",
            position: "absolute",
            top: "2rem"
          }

        })}>
          <div className={mergeStyles({ color: "white", fontWeight: "bold" })}>{curWhy.quoteText}</div>
          <div className={mergeStyles({ fontStyle: "italic", margin: "1rem 0 0 0" })}>{curWhy.quoteBy}</div>
        </div>
          <div className={mergeStyles({ textAlign: "center", backgroundColor: red, display: "flex", justifyContent: "space-evenly", padding: "0.5rem", position: "absolute", width: "10rem", bottom: "2rem", margin: "auto", right: 0 })}>
            {
              whyWayInfo.map((b, i) => {
                return <div onClick={() => {
                  clearInterval(curTimeout);
                  setIndex(i);
                }} className={mergeStyles(i === index ? "active" : undefined, slideDot)} />
              })
            }
          </div></>
      }
    >
      <div className={mergeStyles(obvia, pad2)}>
        <div className={mergeStyles(largeFont, obvia, mediumFont, textBold, { margin: "1rem 0" })} >{curWhy.title}</div>
        <p className={mergeStyles({ lineHeight: "1.75" })}>{curWhy.text}</p>
      </div>

    </SubPageBanner>

    {/* 
    <div className={mergeStyles(spaceEvenly, colWhenSmall)}>
      <div className={mergeStyles({ borderBottom: "1px solid #ccc", backgroundColor: beige })}>
        <div className={mergeStyles({ padding: "1rem" }, textBold, obvia, largeFont, bgRed, textWhite)}>Why Waymark?</div>
        <div className={mergeStyles(obvia, pad2)}>
          <div className={mergeStyles(largeFont, obvia, mediumFont, textBold, { margin: "1rem 0" })} >{curWhy.title}</div>
          <p className={mergeStyles({ lineHeight: "1.75" })}>{curWhy.text}</p>
        </div>
      </div>

      <div className={mergeStyles(imgCover, bgImage(way2), { height: "24rem", position: "relative", display: "flex", justifyContent: "center", alignContent: "center" })} >

      </div>

    </div> */}

    <div className={pad2} >
      <div className={mergeStyles({ overflow: "hidden" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>About Us.</div>
      <p className={pad1}>We founded Waymark Intelligence Solutions, LLC in 2017 as part of our commitment to developing leaders with a sound understanding of international realities and the ethical conduct of statecraft. As intelligence practitioners currently at the forefront of developing innovative analytic techniques at the National Geospatial-Intelligence Agency (NGA), we bring a first-hand understanding of the realities students will face as they step into their careers. We understand the importance of leading by example and taking initiative to do what is right regardless of the obstacles, having overcome many hurdles to institute change at NGA to protect our nation from international threats. We provide mentorship as students walk the path from pupil, to new hire, to change-agent.
      </p>
      <div className={mergeStyles({ overflow: "hidden", marginBottom: "2rem" }, pad1, textBold, obvia, largeFont, bgRed, textWhite)}>Meet The Team.</div>
      <div className={mergeStyles({ overflow: "hidden", marginBottom: "2rem" })}>
        {/* <div className={mergeStyles(floatLeft, imgSingle, bgImage(p1), { backgroundSize: "cover", backgroundPosition: "center right", height: "14rem", width: "14rem", marginRight: "2rem" })} /> */}
        <div className={mergeStyles({ overflow: "hidden", padding: "1rem 0 0 0" }, textBold, obvia, largeFont)}>Dave.</div>
        <p>
          <span className={mergeStyles(obvia, textBold, textRed)}>David Arocho has been working as a GEOINT analyst at NGA since mid-2010, primarily focused on East Asia maritime issues.</span>  Throughout his career, Mr. Arocho has received several awards for his support to senior policymakers during periods of crisis and his contributions to analytic modernization.  While detailed to the NGA Support Team at the Office of Naval Intelligence, Mr. Arocho was a recognized analytic leader and subject matter expert on high interest topics within East Asia. Recently, Mr. Arocho has focused on integrating Lean Startup principles and best practices to create a culture of innovation within the Analysis Directorate at NGA.  Mr. Arocho is currently exploring the application of artificial intelligence to analysis as a member of Project Sagittarius.  Mr. Arocho holds an MS in Defense and Strategic Studies from Missouri State University.  Mr. Arocho enjoys studying the late Tokugawa Shogunate and Meiji Era periods of Japanese history.
        </p>
      </div>
      <div className={mergeStyles({ overflow: "hidden", marginBottom: "2rem" })}>
        {/* <div className={mergeStyles(floatRight, imgSingle, bgImage(p2), { backgroundSize: "cover", backgroundPosition: "center right", height: "14rem", width: "14rem", marginLeft: "2rem" })} /> */}
        <div className={mergeStyles({ overflow: "hidden", padding: "1rem 0 0 0" }, textBold, obvia, largeFont)}>Jeremy.</div>
        <p>
          <span className={mergeStyles(obvia, textBold, textRed)}>Jeremy Boss is a Senior GEOINT Analyst at NGA, focused on Middle Eastern issues.  </span> Mr. Boss is currently heavily involved in the agency's response to the Gaza crisis.  He has played a leading role in crisis management at NGA since beginning his career there in 2011, guiding the agency’s analytic efforts through several high impact events. He authored or contributed to multiple PDB articles, and his reporting was regularly consumed by Pentagon, PACOM, and CENTCOMleadership.  Mr. Boss holds an MA in Government and Politics from the University of Maryland.  A history enthusiast, Mr. Boss is particularly interested in the Roman Empire, especially the reigns of Aurelian and Justinian.
        </p>
      </div>

      <div className={mergeStyles({ overflow: "hidden", marginBottom: "2rem" })}>
        {/* <div className={mergeStyles(floatLeft, imgSingle, bgImage(p3), { backgroundSize: "cover", backgroundPosition: "center left", height: "14rem", width: "14rem", marginRight: "2rem" })} /> */}
        <div className={mergeStyles({ overflow: "hidden", padding: "1rem 0 0 0" }, textBold, obvia, largeFont)}>Nate.</div>
        <p>
          <span className={mergeStyles(obvia, textBold, textRed)}>Nathan M. Kerr has been working as a GEOINT analyst at NGA since 2009, focused on East Asia.</span> In 2013, Mr. Kerr earned a Meritorious Unit Citation from the Director of National Intelligence (DNI) and NGA’s Senior Consumer Award for playing a key role in the community’s support to senior policymakers during several crises. Throughout his career, Mr. Kerr has been at the forefront of improving the agency’s analytic tradecraft and methodology and has developed multiple techniques that have improved the agency’s analysis, information sharing, indications and warning, and long-term planning. Mr. Kerr recently completed a rotation to the Office of Naval Intelligence as the recognized community expert on a high-profile issue and is the lead analytic advisor to a team focused on automating analytic processes using artificial intelligence. Mr. Kerr has an MA in Statecraft and National Security Affairs from the Institute of World Politics. A student of the art of screenwriting, Mr. Kerr enjoys analyzing the story structure of both classic and modern films.
        </p>
      </div>

    </div>
    {/* <div className={mergeStyles(spaceEvenly)}>
      <div className={mergeStyles({ borderBottom: "1px solid #ccc", backgroundColor: beige })}>
        <div className={mergeStyles({ padding: "1rem" }, textBold, obvia, largeFont, bgRed, textWhite)}>Why Waymark?</div>
        
        
        <ul className={mergeStyles({ overflow: "hidden", marginRight: "2rem", marginTop: "2rem", marginBottom: "2rem", ">li": { marginBottom: "1rem" } as IStyle })}>
          <li><BoldThenText bold="Dynamic.">No part of the simulation is scripted: participants are presented with a scenario and a set of goals.  Their policy actions—intertwined with the actions of their rivals—shape the outcome of the simulation.</BoldThenText></li>
          <li><BoldThenText bold="Exciting." >Confront an international crisis in real time, with rapid and unpredictable developments occurring faster than you can react to them.</BoldThenText></li>
          <li><BoldThenText bold="Immersive.">Our simulations take place in an elaborate future reality, with background on rival states, their military forces, their leaders, and their strategic and economic goals.</BoldThenText></li>
        </ul>
      </div>
      <div className={mergeStyles(imgCover, bgImage(ship))} />
    </div>
    <div className={mergeStyles(pad2, { overflow: "hidden" })}>
      <div>
        <div className={mergeStyles(floatLeft, imgCover, bgImage(chess), { marginRight: "2rem", border: "1rem solid white", width: "24rem", height: "18rem", boxShadow: shadow })} />
        <div className={mergeStyles({ overflow: "hidden" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>Gameplay.</div>
        <p className={mergeStyles({ padding: "1rem 0.5rem", overflow: "hidden" })}>
          The simulation will include three to ten rounds (depending on the scenario), each with several components:
        <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
            <li><span className={obviBold}>Situational Update:</span> a briefing from Waymark laying out the updated scenario, based on all team's previous actions.</li>
            <li><span className={obviBold}>Team Deliberation:</span> a period in which teams will deliberate, adjust to the new scenario, and form new policies.</li>
            <li><span className={obviBold}>Negotiation:</span> an opportunity to coordinate with friends or negotiate with adversaries.</li>
            <li><span className={obviBold}>Policy Outbrief:</span> a team briefing to Waymark describing the policies the team is pursuing and their intended outcomes.</li>
          </ul>
          Taking into consideration the policy actions of all teams, and the ways in which they’re likely to interact, Waymark will dynamically adjust the scenario to start the next round.</p>
      </div>
    </div>

    <div className={mergeStyles(pad2, { overflow: "hidden" })}>
      <div>
        <div className={mergeStyles(floatRight, imgCover, bgImage(hourglass), { marginLeft: "2rem", border: "1rem solid white", width: "24rem", height: "18rem", boxShadow: shadow })} />
        <div className={mergeStyles({ overflow: "hidden" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>Scope.</div>
        <p className={mergeStyles({ padding: "1rem 0.5rem", overflow: "hidden" })}>
          Wargames emphasize <i>short-term</i> policies; during each simulation, several days to one week of “simulation time” will pass.
        <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
            <li><span className={obviBold}>Crisis Focus:</span> each scenario is focused on a clear crisis that teams are reacting to.</li>
            <li><span className={obviBold}>Rapid Developments:</span> often events will outpace gameplay, and new developments will be announced in real time as teams deliberate.</li>
            <li><span className={obviBold}>Unexpected Outcomes:</span> local leaders, military, or partisans may take matters into their own hands if not tightly controlled.</li>
            <li><span className={obviBold}>Unintended Consequences:</span> in the fog of war, your opponents may misinterpret your actions and react negatively.</li>
          </ul>
          The crisis environment and short-term focus of our wargames creates an exciting and engaging atmosphere.  Wargames can be added on to intelligence or policy simulations, or be played independently.</p>

      </div>
    </div>

    <div className={mergeStyles(pad2, { overflow: "hidden" })}>
      <div>
        <div className={mergeStyles(floatLeft, imgCover, bgImage(clockwork), { marginRight: "2rem", border: "1rem solid white", width: "24rem", height: "18rem", boxShadow: shadow })} />
        <div className={mergeStyles({ overflow: "hidden" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>Customization.</div>
        <p className={mergeStyles({ padding: "1rem 0.5rem", overflow: "hidden" })}>
          Have a scenario that you’d like to game out?  Waymark can work with you to customize a simulation to fit your interests:
        <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
            <li><span className={obviBold}>Regions and Scenarios:</span> pick the region or scenario that best suits your needs and interest.</li>
            <li><span className={obviBold}>Capabilities and Orders of Battle:</span> customize the tools teams will wield and the military status of forces when the game begins.</li>
            <li><span className={obviBold}>Goals:</span> Set goals that each team will pursue throughout the course of the simulation.</li>
            <li><span className={obviBold}>Gameplay:</span> select the number of rounds or inject additional elements—like negotiations—into the simulation.</li>
          </ul>
          Customization allows you to test out scenarios and explore possibilities.  Pricing will vary depending on the depth of the scenario.  Reach out to us to request a quote.</p>

      </div>
    </div> */}
  </div>;
}


function IntelSimPage() {
  return <div>
    <SubPageBanner title="Intelligence Simulations" imageUrl={intelsim}>
      <ul className={mergeStyles({ overflow: "hidden", marginRight: "2rem", marginTop: "2rem", marginBottom: "2rem", ">li": { marginBottom: "1rem" } as IStyle })}>
        <li><BoldThenText bold="Realistic.">Hundreds of daily intelligence reports cover the strategic maneuvers of rival governments, the tactical movements of rival military forces, and real-time news updates on rapidly evolving international crises.</BoldThenText></li>
        <li><BoldThenText bold="Complex." >Dozens of multi-threaded storylines interact throughout the simulation, some accurate, some inaccurate.  Some relevant, some red herrings.</BoldThenText></li>
        <li><BoldThenText bold="Immersive.">Our simulations take place in an elaborate future reality, with background on rival states, their military forces, their leaders, and their strategic and economic goals.</BoldThenText></li>
      </ul>
    </SubPageBanner>
    <SubPageSection title="Teams." imageUrl={teams}>

      Participants are divided into teams (two to five, depending on the scenario), and each team member is assigned a different role (simulating membership in disparate agencies):
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>All Source Analysts:</span> receive open source intelligence and coordinate the efforts of the team.</li>
        <li><span className={obviBold}>HUMINT Analysts:</span> receive strategic intelligence, primarily about rival intentions and plans.</li>
        <li><span className={obviBold}>IMINT Analysts:</span> receive tactical intelligence, primarily about rival operations and capabilities.</li>
        <li><span className={obviBold}>SIGINT Analysts:</span> receive a mix of strategic and tactical intelligence, often forming a bridge between HUMINT and IMINT reporting.</li>
      </ul>
      All intelligence is text-based, but reflects the type of information analysts working in these roles receive in the real world.
    </SubPageSection>
    <SubPageSection title="Reports." imageUrl={report} imageRight>
      Intelligence reports cover strategic developments, tactical activity in ground, navy, air, and missile forces, and the evolution of the crisis
      <div className={mergeStyles(smallFont, { padding: "0.5rem", margin: "0.5rem 1rem", border: "1px solid black" })}>
        <span className={obviBold}>Source:</span> HUMINT<br />
        <span className={obviBold}>Date:</span> 15 June 2025<br /><br />
        <span className={obviBold}>Location:</span> Riga, Latvia<br /><br />
        <span className={obviBold}>Report:</span> Source reported that Russian officials have been meeting 	secretly with the opposition movement in Latvia.  Source did not know 	the precise nature of the discussions other than they focused on the 	treatment of ethnic Russians and the future state of Latgale and Selija.
      </div>
      Reports are disseminated automatically to participants, via the Waymark Gaming Engine, one to three times per day.

    </SubPageSection>
    <SubPageSection title="Tradecraft." imageUrl={magnify}>
      No individual team member or single report has all the answers.  Teams can only make strong assessments by practicing strong intelligence tradecraft:
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Structured Data Collection:</span> decide what elements—if any—should be captured from reports and stored for later analysis.</li>
        <li><span className={obviBold}>Data Analysis:</span> conduct analysis on your structured data to find patterns and make predictions.</li>
        <li><span className={obviBold}>Structured Analytic Techniques:</span> apply cutting edge intelligence tradecraft to generate hypotheses about what’s happening.</li>
        <li><span className={obviBold}>Collection Requests:</span> use the capabilities of your intelligence apparatus to gain new information and test your hypotheses.</li>
      </ul>
      Work as a team—against the clock—to understand and predict the course of a major international crisis.
    </SubPageSection>
  </div>;
}


function PolicySimPage() {
  return <div>
    <SubPageBanner title="Policy Simulations" imageUrl={polsim}>
      <ul className={mergeStyles({ overflow: "hidden", marginRight: "2rem", marginTop: "2rem", marginBottom: "2rem", ">li": { marginBottom: "1rem" } as IStyle })}>
        <li><BoldThenText bold="Dynamic.">No part of the simulation is scripted: participants are presented with a scenario and a set of goals.  Their policy actions—intertwined with the actions of their rivals—shape the outcome of the simulation.</BoldThenText></li>
        <li><BoldThenText bold="Holistic." >Emphasis is placed on the importance of a multi-faceted approach to international goals.  Successful teams will be those that balance all tools of state power.</BoldThenText></li>
        <li><BoldThenText bold="Immersive.">Our simulations take place in an elaborate future reality, with background on rival states, their military forces, their leaders, and their strategic and economic goals.</BoldThenText></li>
      </ul>
    </SubPageBanner>
    <SubPageSection title="Teams." imageUrl={teams2}>
      Participants are divided into teams (two to five, depending on the scenario), and each team member is assigned a different role (simulating the policy roles of different agencies):
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Diplomatic:</span> propose and integrate a diplomatic strategy to achieve your team’s goals.</li>
        <li><span className={obviBold}>Intelligence:</span> utilize collection assets and covert operations in pursuit of your agenda.</li>
        <li><span className={obviBold}>Military:</span> wield tactical forces—in concert with diplomatic and economic power—to compel outcomes.</li>
        <li><span className={obviBold}>Economic:</span> pursue economic sticks and carrots to drive the international environment in favorable directions.</li>
      </ul>
      Successful teams will pursue <i>balance</i> among these forces.  A "whole of government" approach to policy will yield better outcomes.
    </SubPageSection>
    <SubPageSection title="Gameplay." imageUrl={chess} imageRight>
      The simulation will include three to ten rounds (depending on the scenario), each with several components:
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Situational Update:</span> a briefing from Waymark laying out the updated scenario, based on all team’s previous actions.</li>
        <li><span className={obviBold}>Team Deliberation:</span> a period in which teams will deliberate, adjust to the new scenario, and form new policies.</li>
        <li><span className={obviBold}>Negotiation:</span> an opportunity to coordinate with friends or negotiate with adversaries.</li>
        <li><span className={obviBold}>Policy Outbrief:</span> a team briefing to Waymark describing the policies the team is pursuing and their intended outcomes.</li>
      </ul>
      Taking into consideration the policy actions of all teams, and the ways in which they’re likely to interact, Waymark will dynamically adjust the scenario to start the next round.
    </SubPageSection>
    <SubPageSection title="Scope." imageUrl={agenda}>
      Policy simulations emphasize <i>long-term</i> policies; during each simulation, many months of "simulation time" will pass:
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Efficacy of Diplomacy and Economics:</span> larger blocks of time to pass during the simulation enables longer-term policies to be effective.</li>
        <li><span className={obviBold}>Posturing and Signaling:</span> use diplomacy, the military, and the economy to deliver costly signals.</li>
        <li><span className={obviBold}>Negotiation and Agreement:</span> your allies and rivals have time to let negotiations work, or to secretly plot against them.</li>
        <li><span className={obviBold}>Military Planning:</span> major repositioning of forces from around the globe is possible in a policy simulation.</li>
      </ul>
      Emphasis on long-term outcomes gives participants a workshop in which to experiment with multiple policy tools.
    </SubPageSection>
  </div>;
}


function WargamesPage() {
  return <div>
    <SubPageBanner title="Wargames" imageUrl={ship}>
      <ul className={mergeStyles({ overflow: "hidden", marginRight: "2rem", marginTop: "2rem", marginBottom: "2rem", ">li": { marginBottom: "1rem" } as IStyle })}>
        <li><BoldThenText bold="Dynamic.">No part of the simulation is scripted: participants are presented with a scenario and a set of goals.  Their policy actions—intertwined with the actions of their rivals—shape the outcome of the simulation.</BoldThenText></li>
        <li><BoldThenText bold="Exciting." >Confront an international crisis in real time, with rapid and unpredictable developments occurring faster than you can react to them.</BoldThenText></li>
        <li><BoldThenText bold="Immersive.">Our simulations take place in an elaborate future reality, with background on rival states, their military forces, their leaders, and their strategic and economic goals.</BoldThenText></li>
      </ul>
    </SubPageBanner>
    <SubPageSection title="Gameplay." imageUrl={chess}>
      The simulation will include three to ten rounds (depending on the scenario), each with several components:
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Situational Update:</span> a briefing from Waymark laying out the updated scenario, based on all team's previous actions.</li>
        <li><span className={obviBold}>Team Deliberation:</span> a period in which teams will deliberate, adjust to the new scenario, and form new policies.</li>
        <li><span className={obviBold}>Negotiation:</span> an opportunity to coordinate with friends or negotiate with adversaries.</li>
        <li><span className={obviBold}>Policy Outbrief:</span> a team briefing to Waymark describing the policies the team is pursuing and their intended outcomes.</li>
      </ul>
      Taking into consideration the policy actions of all teams, and the ways in which they’re likely to interact, Waymark will dynamically adjust the scenario to start the next round.
    </SubPageSection>
    <SubPageSection title="Scope." imageUrl={hourglass} imageRight>
      Wargames emphasize <i>short-term</i> policies; during each simulation, several days to one week of "simulation time" will pass.
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Crisis Focus:</span> each scenario is focused on a clear crisis that teams are reacting to.</li>
        <li><span className={obviBold}>Rapid Developments:</span> often events will outpace gameplay, and new developments will be announced in real time as teams deliberate.</li>
        <li><span className={obviBold}>Unexpected Outcomes:</span> local leaders, military, or partisans may take matters into their own hands if not tightly controlled.</li>
        <li><span className={obviBold}>Unintended Consequences:</span> in the fog of war, your opponents may misinterpret your actions and react negatively.</li>
      </ul>
      The crisis environment and short-term focus of our wargames creates an exciting and engaging atmosphere.  Wargames can be added on to intelligence or policy simulations, or be played independently.

    </SubPageSection>
    <SubPageSection title="Customization." imageUrl={clockwork}>
      Have a scenario that you’d like to game out?  Waymark can work with you to customize a simulation to fit your interests:
      <ul className={mergeStyles({ ">li": { marginBottom: "0.25rem" } })}>
        <li><span className={obviBold}>Regions and Scenarios:</span> pick the region or scenario that best suits your needs and interest.</li>
        <li><span className={obviBold}>Capabilities and Orders of Battle:</span> customize the tools teams will wield and the military status of forces when the game begins.</li>
        <li><span className={obviBold}>Goals:</span> Set goals that each team will pursue throughout the course of the simulation.</li>
        <li><span className={obviBold}>Gameplay:</span> select the number of rounds or inject additional elements—like negotiations—into the simulation.</li>
      </ul>
      Customization allows you to test out scenarios and explore possibilities.  Pricing will vary depending on the depth of the scenario.  Reach out to us to request a quote.
    </SubPageSection>
  </div>;
}


function BootcampsPage() {
  return <div>
    <SubPageBanner title="Bootcamps." imageUrl={bootcamps}>
      <ul className={mergeStyles({ overflow: "hidden", marginRight: "2rem", marginTop: "2rem", marginBottom: "2rem", ">li": { marginBottom: "1rem" } as IStyle })}>
        <li><BoldThenText bold="Taught by experts.">All Waymark courses are designed and led by intelligence experts with more than ten years in the field.  Our experts have first-hand experience navigating the real world demands placed on young professionals.</BoldThenText></li>
        <li><BoldThenText bold="Practical." >Our course focus on practical skills and hands on exercises.  Participants work through realistic scenarios as teams, and then discuss outcomes with the instructors.</BoldThenText></li>
        <li><BoldThenText bold="Customizable.">Mix and match courses from our catalogue to meet your organization’s needs.</BoldThenText></li>
      </ul>
    </SubPageBanner>
    <div className={mergeStyles(pad2, { overflow: "hidden" })}>
      <div>
        <div className={mergeStyles({ overflow: "hidden" }, pad1, textBold, obvia, largeFont, bgGray, textWhite)}>Course Catalogue</div>
        <div className={mergeStyles({
          display: "flex", justifyContent: "space-between", flexWrap: "wrap", ">*": {
            flexBasis: "calc(50% - 1rem)", boxSizing: "border-box",
            [lessThanMedium]: {
              flexBasis: "calc(100%)"
            }
          }
        })}>
          {[{
            title: "Introduction to Analysis.",
            question: "What is intelligence and how is it used to form national policy?",
            text: "This course gives participants a broad foundation in the types and sources of intelligence and the analysis cycle, focusing primarily on operational intelligence.",
            courseLenth: "2-4 hours"
          },
          {
            title: "Structuring Data.",
            question: "How do we identify information commonalities and organize data for cooperative analysis?",
            text: "This course introduces participants to data structures, simple and complex schemas, and techniques to leverage multi-faceted datasets to enable analysis.",
            courseLenth: "2-4 hours"
          },
          {
            title: "Structured Analytic Techniques.",
            question: "How do we use a deliberate analytic approach to avoid cognitive biases?",
            text: "This course introduces participants to Richards J. Heuer’s work on structured methods to externalize thought, deconstruct problems, detect assumptions, identify variables, and test hypotheses.",
            courseLenth: "4-8 hours"
          },
          {
            title: "Writing and Briefing for Intelligence.",
            question: "How do we communicate in a bottom line up front format?",
            text: "This course introduces participants to the style and format of intelligence writing, including tips for writing from the inside out and crafting slides for effective briefings.",
            courseLenth: "2-4 hours"
          }


          ].map((m) => {
            return <div className={mergeStyles(pad2, { boxShadow: shadowSmall, display: "flex", flexDirection: "column", border: "1px solid #ccc", margin: "2rem 0 0 0" })}>
              <div className={mergeStyles(largeFont, obvia, textBold)}>{m.title}</div>
              <div className={mergeStyles(mediumFont, obvia, textRed)}>{m.question}</div>
              <div className={mergeStyles({ margin: "1rem 0", flexGrow: 1 })}>{m.text}</div>
              <div className={textRight}><b><i>Course Length:</i></b> {m.courseLenth}</div>
            </div>
          })}
        </div>
      </div>
    </div>

  </div>;
}

function GamingEnginePage() {
  return <div>
    <div className={mergeStyles(pad2, textRed, textBold, obvia, largeFont, textCenter)}>
      Waymark’s one of a kind gaming engine delivers an immersive simulation experience to participants.<br />
      <span className={mergeStyles({ fontWeight: "normal", color: "black" }, obvia, mediumFont, textCenter)}>Coordinate your strategy, craft your policies, and move your military all within our virtual portal.</span>
    </div>
    <div className={mergeStyles({
      display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", ">*": {
        flexBasis: "calc(50% - 4rem)", marginBottom: "1rem",
        [lessThanMedium]: {
          flexBasis: "calc(100% - 2rem)"
        }
      }
    })}>
      {
        [{ title: "Move your Units", img: ge1 }, { title: "Monitor your Adversaries", img: ge2 }, { title: "Order an Attack", img: ge3 }, { title: "Collect Intelligence", img: ge4 }].map((ge) => {
          return <div>
            <div className={mergeStyles({ padding: "0.5rem" }, textCenter, obvia, mediumFont, textWhite, bgGray)}>{ge.title}</div>
            <div className={mergeStyles(imgCover, bgImage(ge.img), { paddingTop: "50%" })} />
          </div>
        })
      }
    </div>
  </div>
}

function App() {
  return (
    <div className={mergeStyles("App", { minHeight: "100vh", backgroundColor: "#f5f5f5" })}>
      <div
        className={mergeStyles({
          position: "relative", maxWidth: maxWidth, backgroundColor: "white", margin: "auto", boxShadow: shadow, overflow: "hidden",
          paddingTop: "4.25rem",
          [lessThanMedium]: {
            paddingTop: "5.5rem"
          }
        })}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch >
            <Route path="/contact">
              <Header alt />
            </Route>
            <Route>
              <Header />
            </Route>
          </Switch>
          <Switch >
            <Route path="/why-waymark">
              <WhyWaymarkPage />
            </Route>
            <Route path="/intelligence-simulations">
              <IntelSimPage />
            </Route>
            <Route path="/policy-simulations">
              <PolicySimPage />
            </Route>
            <Route path="/wargames">
              <WargamesPage />
            </Route>
            <Route path="/bootcamps">
              <BootcampsPage />
            </Route>
            <Route path="/gaming-engine">
              <GamingEnginePage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/" exact>
              <HomePage />
            </Route>
          </Switch>
          <Footer />
          <ProductPopup />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
